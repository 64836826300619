<template>
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-lg-6">
                    <div class="logo">
                        <img class="img-fluid" src="../assets/logos/DentaSpark-Dark.svg" alt/>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 col-lg-3">
                    <div class="links">
                        <ul>
                            <li>
                                <router-link :to="{ name: 'home'}">{{ $t('menu.home') }}</router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'about'}">{{ $t('menu.about') }}</router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'howitworks'}">{{ $t('menu.howitworks') }}</router-link>
                            </li>
                            <!-- <li>
                                <router-link :to="'/office/add'">{{ $t('menu.addnewoffice') }}</router-link>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-md-3 col-lg-3">
                    <div class="links">
                        <ul>
                            <li>
                                <router-link to="/search">{{ $t('menu.findadoctor') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/blog">{{ $t('menu.blog') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/contact">{{ $t('menu.contact') }}</router-link>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>
            <div class="row credits">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <address class="pc">&copy;{{moment().format('YYYY')}} Healthy Dental</address>
                    <div class="social mobile">
                        <ul>
                            <li>
                                <a href="#">
                                    <i class="fa fa-envelope"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fa fa-facebook-f"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fa fa-twitter"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="social pc">
                        <ul>
                            <li>
                                <a href="#">
                                    <i class="fa fa-envelope"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fa fa-facebook-f"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fa fa-twitter"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <address class="mobile">&copy;{{moment().format('YYYY')}} Healthy Dental</address>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "app-footer",
        computed: {}
    };
</script>